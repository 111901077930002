/* leny/kourikoulom
 *
 * /src/components/svg/delnatte-pierre-antoine.js - Name SVG Component
 *
 * coded by leny@flatLand!
 * started at 23/02/2019
 */

import React from "react";

import {css} from "@pwops/emotion-css";
import {varValue} from "../../core/utils";

export default ({className, title}) => {
    const styles = {
        container: css({
            fill: varValue("mainColor"),
        }),
    };

    return (
        <svg
            className={className}
            viewBox={"0 0 74 5"}
            version={"1.1"}
            xmlns={"http://www.w3.org/2000/svg"}>
            <title>{title}</title>
            <g
                stroke={"none"}
                strokeWidth={"1"}
                css={styles.container}
                fillRule={"evenodd"}>
                <path
                    d={[
                        "M0,0",
                        "L1.8189536,0",
                        "C2.1988173,0",
                        "2.54702046,0.0486997433",
                        "2.86357354,0.146100691",
                        "C3.18012662,0.243501639",
                        "3.45284519,0.392035856",
                        "3.68173741,0.591707799",
                        "C3.91062964,0.791379741",
                        "4.0883837,1.04705339",
                        "4.21500494,1.35873643",
                        "C4.34162617,1.67041946",
                        "4.40493583,2.03810252",
                        "4.40493583,2.46179664",
                        "C4.40493583,2.86601058",
                        "4.34040867,3.21786622",
                        "4.21135242,3.51737414",
                        "C4.08229616,3.81688205",
                        "3.90210711,4.06768573",
                        "3.67077986,4.26979269",
                        "C3.43945261,4.47189966",
                        "3.16551655,4.62286887",
                        "2.84896347,4.72270484",
                        "C2.53241039,4.82254081",
                        "2.18664222,4.87245805",
                        "1.81164857,4.87245805",
                        "L0,4.87245805",
                        "L0,0",
                        "Z",
                        "M1.73129319,4.05429418",
                        "C2.28647859,4.05429418",
                        "2.71016636,3.92036988",
                        "3.0023692,3.65251728",
                        "C3.29457204,3.38466467",
                        "3.44067127,2.99263174",
                        "3.44067127,2.47640671",
                        "C3.44067127,1.96505174",
                        "3.30552949,1.56084387",
                        "3.03524186,1.26377098",
                        "C2.76495423,0.966698087",
                        "2.33030902,0.81816387",
                        "1.73129319,0.81816387",
                        "L0.920434353,0.81816387",
                        "L0.920434353,4.05429418",
                        "L1.73129319,4.05429418",
                        "Z",
                        "M8.32773939,4.3757157",
                        "C8.31312925,4.39032584",
                        "8.27295196,4.43050313",
                        "8.20720632,4.49624877",
                        "C8.14146068,4.56199441",
                        "8.04649618,4.63139154",
                        "7.92230997,4.70444225",
                        "C7.79812376,4.77749296",
                        "7.6398496,4.84323762",
                        "7.44748272,4.90167818",
                        "C7.25511585,4.96011875",
                        "7.02500957,4.9893386",
                        "6.75715696,4.9893386",
                        "C6.48443431,4.9893386",
                        "6.23606562,4.94307384",
                        "6.01204344,4.85054294",
                        "C5.78802126,4.75801204",
                        "5.59687476,4.63017522",
                        "5.43859822,4.46702863",
                        "C5.28032168,4.30388204",
                        "5.15857232,4.10786557",
                        "5.0733465,3.87897335",
                        "C4.98812067,3.65008112",
                        "4.94550839,3.39684245",
                        "4.94550839,3.11924975",
                        "C4.94550839,2.86600729",
                        "4.98812067,2.63103103",
                        "5.0733465,2.41431392",
                        "C5.15857232,2.19759681",
                        "5.2778867,2.00888531",
                        "5.43129319,1.84817374",
                        "C5.58469968,1.68746218",
                        "5.76732372,1.56084285",
                        "5.97917078,1.46831194",
                        "C6.19101784,1.37578104",
                        "6.4259941,1.32951629",
                        "6.68410661,1.32951629",
                        "C6.99578965,1.32951629",
                        "7.25998575,1.38673849",
                        "7.47670286,1.5011846",
                        "C7.69341997,1.61563071",
                        "7.86752155,1.76538242",
                        "7.99901283,1.95044423",
                        "C8.13050411,2.13550603",
                        "8.22546861,2.34491492",
                        "8.28390918,2.5786772",
                        "C8.34234975,2.81243947",
                        "8.3715696,3.05106821",
                        "8.3715696,3.29457058",
                        "L8.3715696,3.41875617",
                        "L5.8148075,3.41875617",
                        "C5.8148075,3.5453774",
                        "5.84280985,3.6598218",
                        "5.8988154,3.76209279",
                        "C5.95482094,3.86436379",
                        "6.02787056,3.95202333",
                        "6.11796644,4.02507404",
                        "C6.20806231,4.09812475",
                        "6.31033177,4.15412945",
                        "6.42477789,4.19308983",
                        "C6.539224,4.23205021",
                        "6.6524509,4.25153011",
                        "6.76446199,4.25153011",
                        "C6.93004361,4.25153011",
                        "7.07492534,4.23570269",
                        "7.19911155,4.20404738",
                        "C7.32329776,4.17239208",
                        "7.4292197,4.13586727",
                        "7.51688055,4.09447187",
                        "C7.60454141,4.05307646",
                        "7.67637353,4.00924669",
                        "7.73237907,3.96298124",
                        "C7.78838462,3.91671579",
                        "7.83099689,3.87897349",
                        "7.86021718,3.84975321",
                        "L8.32773939,4.3757157",
                        "Z",
                        "M7.48766041,2.79782823",
                        "C7.48766041,2.71016738",
                        "7.4706155,2.62250784",
                        "7.43652517,2.53484699",
                        "C7.40243484,2.44718614",
                        "7.3525176,2.36804905",
                        "7.28677196,2.29743337",
                        "C7.22102632,2.22681768",
                        "7.13701927,2.16837799",
                        "7.03474827,2.12211254",
                        "C6.93247728,2.07584709",
                        "6.81559789,2.05271471",
                        "6.68410661,2.05271471",
                        "C6.54287524,2.05271471",
                        "6.4174734,2.07584709",
                        "6.30789733,2.12211254",
                        "C6.19832127,2.16837799",
                        "6.10700925,2.22681768",
                        "6.03395854,2.29743337",
                        "C5.96090783,2.36804905",
                        "5.90490312,2.44718614",
                        "5.86594274,2.53484699",
                        "C5.82698237,2.62250784",
                        "5.80750247,2.71016738",
                        "5.80750247,2.79782823",
                        "L7.48766041,2.79782823",
                        "Z",
                        "M9.03632774,0",
                        "L9.89832182,0",
                        "L9.89832182,4.87245805",
                        "L9.03632774,4.87245805",
                        "L9.03632774,0",
                        "Z",
                        "M10.7457058,1.44639684",
                        "L11.5711747,1.44639684",
                        "L11.5930898,1.83356367",
                        "C11.6369203,1.7848632",
                        "11.6892725,1.73372847",
                        "11.7501481,1.68015795",
                        "C11.8110237,1.62658743",
                        "11.8840733,1.57667019",
                        "11.9692991,1.53040474",
                        "C12.0545249,1.48413929",
                        "12.1507069,1.44639699",
                        "12.257848,1.4171767",
                        "C12.364989,1.38795642",
                        "12.4867384,1.3733465",
                        "12.6230997,1.3733465",
                        "C13.0516639,1.3733465",
                        "13.3779522,1.50118332",
                        "13.6019743,1.75686081",
                        "C13.8259965,2.0125383",
                        "13.9380059,2.35708898",
                        "13.9380059,2.7905232",
                        "L13.9380059,4.87245805",
                        "L13.0760118,4.87245805",
                        "L13.0760118,2.79782823",
                        "C13.0760118,2.5786761",
                        "13.0163547,2.40944449",
                        "12.8970385,2.29012833",
                        "C12.7777223,2.17081217",
                        "12.6231007,2.11115499",
                        "12.4331688,2.11115499",
                        "C12.3162877,2.11115499",
                        "12.2067132,2.13428736",
                        "12.1044423,2.18055281",
                        "C12.0021713,2.22681826",
                        "11.9145117,2.29134542",
                        "11.841461,2.37413623",
                        "C11.7684103,2.45692703",
                        "11.7111881,2.55310903",
                        "11.6697927,2.66268509",
                        "C11.6283973,2.77226116",
                        "11.6076999,2.89035804",
                        "11.6076999,3.01697927",
                        "L11.6076999,4.87245805",
                        "L10.7457058,4.87245805",
                        "L10.7457058,1.44639684",
                        "Z",
                        "M16.7942744,4.55103653",
                        "C16.7017435,4.66304762",
                        "16.5605143,4.76288209",
                        "16.3705824,4.85054294",
                        "C16.1806506,4.93820379",
                        "15.9688067,4.98203356",
                        "15.7350444,4.98203356",
                        "C15.593813,4.98203356",
                        "15.4538013,4.96011868",
                        "15.3150049,4.91628825",
                        "C15.1762086,4.87245783",
                        "15.0495893,4.80549568",
                        "14.9351431,4.7153998",
                        "C14.820697,4.62530393",
                        "14.7281675,4.50964204",
                        "14.6575518,4.36841066",
                        "C14.5869361,4.22717929",
                        "14.5516288,4.06160016",
                        "14.5516288,3.87166831",
                        "C14.5516288,3.67686642",
                        "14.5881536,3.50276483",
                        "14.6612043,3.34935834",
                        "C14.7342551,3.19595185",
                        "14.8365245,3.06689753",
                        "14.9680158,2.96219151",
                        "C15.0995071,2.85748549",
                        "15.2577812,2.77834841",
                        "15.442843,2.72477789",
                        "C15.6279048,2.67120737",
                        "15.8324438,2.64442251",
                        "16.0564659,2.64442251",
                        "C16.1246466,2.64442251",
                        "16.2001312,2.64564",
                        "16.282922,2.64807502",
                        "C16.3657128,2.65051005",
                        "16.4460674,2.65416253",
                        "16.5239882,2.65903258",
                        "C16.611649,2.66390262",
                        "16.7017435,2.6687726",
                        "16.7942744,2.67364265",
                        "L16.7942744,2.59328727",
                        "C16.7942744,2.42283561",
                        "16.7260948,2.28891131",
                        "16.5897335,2.19151037",
                        "C16.4533721,2.09410942",
                        "16.2756181,2.04540967",
                        "16.0564659,2.04540967",
                        "C15.8129636,2.04540967",
                        "15.6181646,2.07706451",
                        "15.4720632,2.14037512",
                        "C15.3259618,2.20368574",
                        "15.1969074,2.27186538",
                        "15.0848963,2.34491609",
                        "L14.6904245,1.76051333",
                        "C14.7586051,1.71181285",
                        "14.8365247,1.66189562",
                        "14.9241856,1.61076012",
                        "C15.0118464,1.55962462",
                        "15.1141159,1.51335986",
                        "15.230997,1.47196446",
                        "C15.3478782,1.43056906",
                        "15.48302,1.39647924",
                        "15.6364265,1.36969398",
                        "C15.7898329,1.34290872",
                        "15.9688045,1.32951629",
                        "16.1733465,1.32951629",
                        "C16.3924986,1.32951629",
                        "16.5909501,1.35995363",
                        "16.7687068,1.42082922",
                        "C16.9464635,1.48170481",
                        "17.0986502,1.56692936",
                        "17.2252715,1.67650543",
                        "C17.3518927,1.7860815",
                        "17.4492922,1.9175708",
                        "17.5174729,2.0709773",
                        "C17.5856535,2.22438379",
                        "17.6197433,2.39118041",
                        "17.6197433,2.57137216",
                        "L17.6197433,4.87245805",
                        "L16.8161895,4.87245805",
                        "L16.7942744,4.55103653",
                        "Z",
                        "M16.7942744,3.32379072",
                        "C16.7066136,3.31892067",
                        "16.621389,3.3140507",
                        "16.5385982,3.30918065",
                        "C16.4704176,3.3043106",
                        "16.3985854,3.30065812",
                        "16.3230997,3.2982231",
                        "C16.247614,3.29578808",
                        "16.1830868,3.29457058",
                        "16.1295163,3.29457058",
                        "C15.8519236,3.29457058",
                        "15.6571246,3.34935779",
                        "15.5451135,3.45893386",
                        "C15.4331024,3.56850993",
                        "15.3770977,3.69391177",
                        "15.3770977,3.83514314",
                        "C15.3770977,3.98124456",
                        "15.42945,4.09568896",
                        "15.534156,4.17847976",
                        "C15.638862,4.26127057",
                        "15.7934837,4.30266535",
                        "15.9980257,4.30266535",
                        "C16.1002967,4.30266535",
                        "16.1989136,4.28562044",
                        "16.2938796,4.25153011",
                        "C16.3888455,4.21743978",
                        "16.4728525,4.17117502",
                        "16.5459033,4.11273445",
                        "C16.618954,4.05429388",
                        "16.6786112,3.98611424",
                        "16.7248766,3.90819348",
                        "C16.7711421,3.83027273",
                        "16.7942744,3.74748316",
                        "16.7942744,3.65982231",
                        "L16.7942744,3.32379072",
                        "Z",
                        "M18.7447187,2.14768016",
                        "L18.1603159,2.14768016",
                        "L18.1603159,1.44639684",
                        "L18.7447187,1.44639684",
                        "L18.7447187,0.336031589",
                        "L19.6067127,0.336031589",
                        "L19.6067127,1.44639684",
                        "L20.5271471,1.44639684",
                        "L20.5271471,2.14768016",
                        "L19.6067127,2.14768016",
                        "L19.6067127,3.64521224",
                        "C19.6067127,3.84001414",
                        "19.6395851,3.98976585",
                        "19.7053307,4.09447187",
                        "C19.7710763,4.19917788",
                        "19.8769983,4.25153011",
                        "20.0230997,4.25153011",
                        "C20.159461,4.25153011",
                        "20.267818,4.23205021",
                        "20.3481737,4.19308983",
                        "C20.4285295,4.15412945",
                        "20.5052316,4.1078647",
                        "20.5782823,4.05429418",
                        "L20.9435341,4.5875617",
                        "C20.7730824,4.72879307",
                        "20.6050683,4.82984504",
                        "20.4394867,4.89072063",
                        "C20.2739051,4.95159622",
                        "20.088846,4.98203356",
                        "19.884304,4.98203356",
                        "C19.674892,4.98203356",
                        "19.4971379,4.95159622",
                        "19.3510365,4.89072063",
                        "C19.2049351,4.82984504",
                        "19.0868382,4.743403",
                        "18.9967423,4.63139191",
                        "C18.9066465,4.51938082",
                        "18.8421193,4.38423903",
                        "18.8031589,4.22596249",
                        "C18.7641986,4.06768595",
                        "18.7447187,3.89358437",
                        "18.7447187,3.70365252",
                        "L18.7447187,2.14768016",
                        "Z",
                        "M21.571767,2.14768016",
                        "L20.9873643,2.14768016",
                        "L20.9873643,1.44639684",
                        "L21.571767,1.44639684",
                        "L21.571767,0.336031589",
                        "L22.4337611,0.336031589",
                        "L22.4337611,1.44639684",
                        "L23.3541955,1.44639684",
                        "L23.3541955,2.14768016",
                        "L22.4337611,2.14768016",
                        "L22.4337611,3.64521224",
                        "C22.4337611,3.84001414",
                        "22.4666334,3.98976585",
                        "22.5323791,4.09447187",
                        "C22.5981247,4.19917788",
                        "22.7040467,4.25153011",
                        "22.8501481,4.25153011",
                        "C22.9865094,4.25153011",
                        "23.0948663,4.23205021",
                        "23.1752221,4.19308983",
                        "C23.2555779,4.15412945",
                        "23.33228,4.1078647",
                        "23.4053307,4.05429418",
                        "L23.7705824,4.5875617",
                        "C23.6001308,4.72879307",
                        "23.4321167,4.82984504",
                        "23.266535,4.89072063",
                        "C23.1009534,4.95159622",
                        "22.9158944,4.98203356",
                        "22.7113524,4.98203356",
                        "C22.5019404,4.98203356",
                        "22.3241863,4.95159622",
                        "22.1780849,4.89072063",
                        "C22.0319835,4.82984504",
                        "21.9138866,4.743403",
                        "21.8237907,4.63139191",
                        "C21.7336948,4.51938082",
                        "21.6691677,4.38423903",
                        "21.6302073,4.22596249",
                        "C21.5912469,4.06768595",
                        "21.571767,3.89358437",
                        "21.571767,3.70365252",
                        "L21.571767,2.14768016",
                        "Z",
                        "M27.2916091,4.3757157",
                        "C27.2769989,4.39032584",
                        "27.2368217,4.43050313",
                        "27.171076,4.49624877",
                        "C27.1053304,4.56199441",
                        "27.0103659,4.63139154",
                        "26.8861797,4.70444225",
                        "C26.7619935,4.77749296",
                        "26.6037193,4.84323762",
                        "26.4113524,4.90167818",
                        "C26.2189855,4.96011875",
                        "25.9888793,4.9893386",
                        "25.7210267,4.9893386",
                        "C25.448304,4.9893386",
                        "25.1999353,4.94307384",
                        "24.9759131,4.85054294",
                        "C24.7518909,4.75801204",
                        "24.5607445,4.63017522",
                        "24.4024679,4.46702863",
                        "C24.2441914,4.30388204",
                        "24.122442,4.10786557",
                        "24.0372162,3.87897335",
                        "C23.9519904,3.65008112",
                        "23.9093781,3.39684245",
                        "23.9093781,3.11924975",
                        "C23.9093781,2.86600729",
                        "23.9519904,2.63103103",
                        "24.0372162,2.41431392",
                        "C24.122442,2.19759681",
                        "24.2417564,2.00888531",
                        "24.3951629,1.84817374",
                        "C24.5485694,1.68746218",
                        "24.7311934,1.56084285",
                        "24.9430405,1.46831194",
                        "C25.1548875,1.37578104",
                        "25.3898638,1.32951629",
                        "25.6479763,1.32951629",
                        "C25.9596593,1.32951629",
                        "26.2238554,1.38673849",
                        "26.4405726,1.5011846",
                        "C26.6572897,1.61563071",
                        "26.8313912,1.76538242",
                        "26.9628825,1.95044423",
                        "C27.0943738,2.13550603",
                        "27.1893383,2.34491492",
                        "27.2477789,2.5786772",
                        "C27.3062194,2.81243947",
                        "27.3354393,3.05106821",
                        "27.3354393,3.29457058",
                        "L27.3354393,3.41875617",
                        "L24.7786772,3.41875617",
                        "C24.7786772,3.5453774",
                        "24.8066795,3.6598218",
                        "24.8626851,3.76209279",
                        "C24.9186906,3.86436379",
                        "24.9917403,3.95202333",
                        "25.0818361,4.02507404",
                        "C25.171932,4.09812475",
                        "25.2742015,4.15412945",
                        "25.3886476,4.19308983",
                        "C25.5030937,4.23205021",
                        "25.6163206,4.25153011",
                        "25.7283317,4.25153011",
                        "C25.8939133,4.25153011",
                        "26.038795,4.23570269",
                        "26.1629812,4.20404738",
                        "C26.2871675,4.17239208",
                        "26.3930894,4.13586727",
                        "26.4807502,4.09447187",
                        "C26.5684111,4.05307646",
                        "26.6402432,4.00924669",
                        "26.6962488,3.96298124",
                        "C26.7522543,3.91671579",
                        "26.7948666,3.87897349",
                        "26.8240869,3.84975321",
                        "L27.2916091,4.3757157",
                        "Z",
                        "M26.4515301,2.79782823",
                        "C26.4515301,2.71016738",
                        "26.4344852,2.62250784",
                        "26.4003949,2.53484699",
                        "C26.3663045,2.44718614",
                        "26.3163873,2.36804905",
                        "26.2506417,2.29743337",
                        "C26.184896,2.22681768",
                        "26.100889,2.16837799",
                        "25.998618,2.12211254",
                        "C25.896347,2.07584709",
                        "25.7794676,2.05271471",
                        "25.6479763,2.05271471",
                        "C25.5067449,2.05271471",
                        "25.3813431,2.07584709",
                        "25.271767,2.12211254",
                        "C25.162191,2.16837799",
                        "25.0708789,2.22681768",
                        "24.9978282,2.29743337",
                        "C24.9247775,2.36804905",
                        "24.8687728,2.44718614",
                        "24.8298124,2.53484699",
                        "C24.7908521,2.62250784",
                        "24.7713722,2.71016738",
                        "24.7713722,2.79782823",
                        "L26.4515301,2.79782823",
                        "Z",
                        "M29.5269497,0",
                        "L31.0244817,0",
                        "C31.3410348,0",
                        "31.6076659,0.0353073139",
                        "31.824383,0.105923001",
                        "C32.0411001,0.176538688",
                        "32.2152017,0.275155668",
                        "32.346693,0.4017769",
                        "C32.4781843,0.528398132",
                        "32.5731488,0.676932349",
                        "32.6315893,0.847384008",
                        "C32.6900299,1.01783567",
                        "32.7192498,1.20289469",
                        "32.7192498,1.40256663",
                        "C32.7192498,1.60223858",
                        "32.6900299,1.7872976",
                        "32.6315893,1.95774926",
                        "C32.5731488,2.12820092",
                        "32.4781843,2.27673514",
                        "32.346693,2.40335637",
                        "C32.2152017,2.5299776",
                        "32.0411001,2.62859458",
                        "31.824383,2.69921027",
                        "C31.6076659,2.76982595",
                        "31.3410348,2.80513327",
                        "31.0244817,2.80513327",
                        "L29.8775913,2.80513327",
                        "L29.8775913,4.87245805",
                        "L29.5269497,4.87245805",
                        "L29.5269497,0",
                        "Z",
                        "M31.1267522,2.47640671",
                        "C31.5358362,2.47640671",
                        "31.8402096,2.38509469",
                        "32.0398815,2.20246792",
                        "C32.2395535,2.01984114",
                        "32.339388,1.75321005",
                        "32.339388,1.40256663",
                        "C32.339388,1.05192322",
                        "32.2395535,0.785292127",
                        "32.0398815,0.60266535",
                        "C31.8402096,0.420038574",
                        "31.5358362,0.328726555",
                        "31.1267522,0.328726555",
                        "L29.8775913,0.328726555",
                        "L29.8775913,2.47640671",
                        "L31.1267522,2.47640671",
                        "Z",
                        "M33.5008885,1.51214215",
                        "L33.829615,1.51214215",
                        "L33.829615,4.87245805",
                        "L33.5008885,4.87245805",
                        "L33.5008885,1.51214215",
                        "Z",
                        "M33.4351431,0.328726555",
                        "L33.8953603,0.328726555",
                        "L33.8953603,0.810858835",
                        "L33.4351431,0.810858835",
                        "L33.4351431,0.328726555",
                        "Z",
                        "M37.7670286,4.36841066",
                        "C37.6209272,4.55347246",
                        "37.4224758,4.6983542",
                        "37.1716683,4.80306022",
                        "C36.9208609,4.90776624",
                        "36.6639697,4.96011846",
                        "36.4009872,4.96011846",
                        "C36.1526148,4.96011846",
                        "35.9225085,4.91750618",
                        "35.7106614,4.83228036",
                        "C35.4988143,4.74705453",
                        "35.3174078,4.62652266",
                        "35.1664363,4.47068115",
                        "C35.0154649,4.31483963",
                        "34.897368,4.12856311",
                        "34.8121422,3.911846",
                        "C34.7269163,3.69512889",
                        "34.684304,3.45528266",
                        "34.684304,3.1923001",
                        "C34.684304,2.94879773",
                        "34.7244813,2.71990894",
                        "34.8048371,2.50562685",
                        "C34.8851929,2.29134477",
                        "34.9959848,2.10385075",
                        "35.1372162,1.94313919",
                        "C35.2784476,1.78242763",
                        "35.4488967,1.65580829",
                        "35.6485686,1.56327739",
                        "C35.8482406,1.47074649",
                        "36.0673894,1.42448174",
                        "36.3060217,1.42448174",
                        "C36.5349139,1.42448174",
                        "36.7479753,1.46465903",
                        "36.9452122,1.54501481",
                        "C37.1424492,1.62537059",
                        "37.3128983,1.74346747",
                        "37.4565647,1.89930898",
                        "C37.6002311,2.0551505",
                        "37.7122405,2.24873198",
                        "37.7925962,2.48005923",
                        "C37.872952,2.71138648",
                        "37.9131293,2.97801758",
                        "37.9131293,3.27996051",
                        "L35.0495558,3.27996051",
                        "C35.0544258,3.46989236",
                        "35.0909506,3.64886392",
                        "35.1591313,3.81688055",
                        "C35.227312,3.98489719",
                        "35.3198415,4.13099642",
                        "35.4367226,4.25518263",
                        "C35.5536037,4.37936883",
                        "35.6936155,4.47676832",
                        "35.8567621,4.54738401",
                        "C36.0199087,4.61799969",
                        "36.2013152,4.65330701",
                        "36.4009872,4.65330701",
                        "C36.5032582,4.65330701",
                        "36.6091801,4.64234957",
                        "36.7187562,4.62043435",
                        "C36.8283322,4.59851914",
                        "36.9342542,4.56564681",
                        "37.0365252,4.52181639",
                        "C37.1387962,4.47798596",
                        "37.2325432,4.42685123",
                        "37.317769,4.36841066",
                        "C37.4029948,4.30997009",
                        "37.474827,4.24422544",
                        "37.5332675,4.17117473",
                        "L37.7670286,4.36841066",
                        "Z",
                        "M37.5551826,2.99506417",
                        "C37.5113522,2.58111014",
                        "37.3798629,2.26699679",
                        "37.1607108,2.05271471",
                        "C36.9415586,1.83843262",
                        "36.6566651,1.73129319",
                        "36.3060217,1.73129319",
                        "C36.12583,1.73129319",
                        "35.9614683,1.76416552",
                        "35.8129319,1.82991115",
                        "C35.6643954,1.89565679",
                        "35.5365586,1.98575132",
                        "35.4294176,2.10019743",
                        "C35.3222765,2.21464355",
                        "35.2358345,2.34856784",
                        "35.1700888,2.50197433",
                        "C35.1043432,2.65538083",
                        "35.0666009,2.81974246",
                        "35.0568608,2.99506417",
                        "L37.5551826,2.99506417",
                        "Z",
                        "M38.7678184,2.2207305",
                        "L38.7678184,1.98331688",
                        "L38.7678184,1.80799605",
                        "C38.7678184,1.75686055",
                        "38.7666009,1.70816081",
                        "38.7641658,1.66189536",
                        "C38.7617308,1.61562991",
                        "38.7580783,1.56571267",
                        "38.7532083,1.51214215",
                        "L39.0600197,1.51214215",
                        "C39.0648898,1.56571267",
                        "39.0685423,1.6168474",
                        "39.0709773,1.66554788",
                        "C39.0734123,1.71424835",
                        "39.0758473,1.76660058",
                        "39.0782823,1.82260612",
                        "C39.0807174,1.87861167",
                        "39.0819348,1.94070384",
                        "39.0819348,2.0088845",
                        "L39.0819348,2.24995064",
                        "C39.1208952,2.17689993",
                        "39.1732475,2.09532786",
                        "39.2389931,2.00523198",
                        "C39.3047387,1.91513611",
                        "39.3887458,1.83112905",
                        "39.4910168,1.75320829",
                        "C39.5932878,1.67528753",
                        "39.7126021,1.60954288",
                        "39.8489635,1.55597236",
                        "C39.9853248,1.50240184",
                        "40.143599,1.47561698",
                        "40.3237907,1.47561698",
                        "C40.4309318,1.47561698",
                        "40.5234613,1.49266189",
                        "40.601382,1.52675222",
                        "L40.5064166,1.84086871",
                        "C40.4723263,1.82625856",
                        "40.432149,1.81651862",
                        "40.3858835,1.81164857",
                        "C40.3396181,1.80677852",
                        "40.2970058,1.80434353",
                        "40.2580454,1.80434353",
                        "C40.0827237,1.80434353",
                        "39.9244495,1.84330333",
                        "39.7832182,1.92122409",
                        "C39.6419868,1.99914485",
                        "39.5202374,2.10141431",
                        "39.4179664,2.22803554",
                        "C39.3156954,2.35465677",
                        "39.2365584,2.49953851",
                        "39.1805528,2.66268509",
                        "C39.1245473,2.82583168",
                        "39.0965449,2.99019331",
                        "39.0965449,3.15577493",
                        "L39.0965449,4.87245805",
                        "L38.7678184,4.87245805",
                        "L38.7678184,2.2207305",
                        "Z",
                        "M41.229615,2.2207305",
                        "L41.229615,1.98331688",
                        "L41.229615,1.80799605",
                        "C41.229615,1.75686055",
                        "41.2283975,1.70816081",
                        "41.2259625,1.66189536",
                        "C41.2235275,1.61562991",
                        "41.219875,1.56571267",
                        "41.2150049,1.51214215",
                        "L41.5218164,1.51214215",
                        "C41.5266864,1.56571267",
                        "41.5303389,1.6168474",
                        "41.5327739,1.66554788",
                        "C41.535209,1.71424835",
                        "41.5376439,1.76660058",
                        "41.540079,1.82260612",
                        "C41.542514,1.87861167",
                        "41.5437315,1.94070384",
                        "41.5437315,2.0088845",
                        "L41.5437315,2.24995064",
                        "C41.5826919,2.17689993",
                        "41.6350441,2.09532786",
                        "41.7007897,2.00523198",
                        "C41.7665354,1.91513611",
                        "41.8505424,1.83112905",
                        "41.9528134,1.75320829",
                        "C42.0550844,1.67528753",
                        "42.1743988,1.60954288",
                        "42.3107601,1.55597236",
                        "C42.4471214,1.50240184",
                        "42.6053956,1.47561698",
                        "42.7855874,1.47561698",
                        "C42.8927284,1.47561698",
                        "42.9852579,1.49266189",
                        "43.0631787,1.52675222",
                        "L42.9682132,1.84086871",
                        "C42.9341229,1.82625856",
                        "42.8939456,1.81651862",
                        "42.8476802,1.81164857",
                        "C42.8014147,1.80677852",
                        "42.7588024,1.80434353",
                        "42.7198421,1.80434353",
                        "C42.5445203,1.80434353",
                        "42.3862462,1.84330333",
                        "42.2450148,1.92122409",
                        "C42.1037834,1.99914485",
                        "41.9820341,2.10141431",
                        "41.8797631,2.22803554",
                        "C41.7774921,2.35465677",
                        "41.698355,2.49953851",
                        "41.6423495,2.66268509",
                        "C41.5863439,2.82583168",
                        "41.5583416,2.99019331",
                        "41.5583416,3.15577493",
                        "L41.5583416,4.87245805",
                        "L41.229615,4.87245805",
                        "L41.229615,2.2207305",
                        "Z",
                        "M46.3723593,4.36841066",
                        "C46.2262579,4.55347246",
                        "46.0278065,4.6983542",
                        "45.776999,4.80306022",
                        "C45.5261916,4.90776624",
                        "45.2693004,4.96011846",
                        "45.0063179,4.96011846",
                        "C44.7579455,4.96011846",
                        "44.5278392,4.91750618",
                        "44.3159921,4.83228036",
                        "C44.104145,4.74705453",
                        "43.9227385,4.62652266",
                        "43.771767,4.47068115",
                        "C43.6207956,4.31483963",
                        "43.5026987,4.12856311",
                        "43.4174729,3.911846",
                        "C43.332247,3.69512889",
                        "43.2896347,3.45528266",
                        "43.2896347,3.1923001",
                        "C43.2896347,2.94879773",
                        "43.329812,2.71990894",
                        "43.4101678,2.50562685",
                        "C43.4905236,2.29134477",
                        "43.6013155,2.10385075",
                        "43.7425469,1.94313919",
                        "C43.8837783,1.78242763",
                        "44.0542274,1.65580829",
                        "44.2538993,1.56327739",
                        "C44.4535713,1.47074649",
                        "44.6727201,1.42448174",
                        "44.9113524,1.42448174",
                        "C45.1402446,1.42448174",
                        "45.353306,1.46465903",
                        "45.5505429,1.54501481",
                        "C45.7477799,1.62537059",
                        "45.918229,1.74346747",
                        "46.0618954,1.89930898",
                        "C46.2055618,2.0551505",
                        "46.3175712,2.24873198",
                        "46.3979269,2.48005923",
                        "C46.4782827,2.71138648",
                        "46.51846,2.97801758",
                        "46.51846,3.27996051",
                        "L43.6548865,3.27996051",
                        "C43.6597565,3.46989236",
                        "43.6962813,3.64886392",
                        "43.764462,3.81688055",
                        "C43.8326427,3.98489719",
                        "43.9251722,4.13099642",
                        "44.0420533,4.25518263",
                        "C44.1589344,4.37936883",
                        "44.2989462,4.47676832",
                        "44.4620928,4.54738401",
                        "C44.6252394,4.61799969",
                        "44.8066459,4.65330701",
                        "45.0063179,4.65330701",
                        "C45.1085889,4.65330701",
                        "45.2145108,4.64234957",
                        "45.3240869,4.62043435",
                        "C45.4336629,4.59851914",
                        "45.5395849,4.56564681",
                        "45.6418559,4.52181639",
                        "C45.7441269,4.47798596",
                        "45.8378739,4.42685123",
                        "45.9230997,4.36841066",
                        "C46.0083255,4.30997009",
                        "46.0801577,4.24422544",
                        "46.1385982,4.17117473",
                        "L46.3723593,4.36841066",
                        "Z",
                        "M46.1605133,2.99506417",
                        "C46.1166829,2.58111014",
                        "45.9851936,2.26699679",
                        "45.7660415,2.05271471",
                        "C45.5468893,1.83843262",
                        "45.2619958,1.73129319",
                        "44.9113524,1.73129319",
                        "C44.7311607,1.73129319",
                        "44.566799,1.76416552",
                        "44.4182626,1.82991115",
                        "C44.2697261,1.89565679",
                        "44.1418893,1.98575132",
                        "44.0347483,2.10019743",
                        "C43.9276072,2.21464355",
                        "43.8411652,2.34856784",
                        "43.7754195,2.50197433",
                        "C43.7096739,2.65538083",
                        "43.6719316,2.81974246",
                        "43.6621915,2.99506417",
                        "L46.1605133,2.99506417",
                        "Z",
                        "M47.4461994,2.65172754",
                        "L49.4550839,2.65172754",
                        "L49.4550839,2.9804541",
                        "L47.4461994,2.9804541",
                        "L47.4461994,2.65172754",
                        "Z",
                        "M51.8949654,0",
                        "L52.2456071,0",
                        "L54.2398815,4.87245805",
                        "L53.8381046,4.87245805",
                        "L53.2025666,3.30187562",
                        "L50.9087858,3.30187562",
                        "L50.2805528,4.87245805",
                        "L49.900691,4.87245805",
                        "L51.8949654,0",
                        "Z",
                        "M53.071076,2.97314906",
                        "L52.0556762,0.445607108",
                        "L51.0402764,2.97314906",
                        "L53.071076,2.97314906",
                        "Z",
                        "M54.9046397,2.2207305",
                        "L54.9046397,1.98331688",
                        "L54.9046397,1.80799605",
                        "C54.9046397,1.75686055",
                        "54.9034222,1.70816081",
                        "54.9009872,1.66189536",
                        "C54.8985521,1.61562991",
                        "54.8948997,1.56571267",
                        "54.8900296,1.51214215",
                        "L55.1968411,1.51214215",
                        "C55.2017111,1.56571267",
                        "55.2053636,1.61441242",
                        "55.2077986,1.65824284",
                        "C55.2102336,1.70207327",
                        "55.2126686,1.74955552",
                        "55.2151037,1.80069102",
                        "C55.2175387,1.85182651",
                        "55.2187562,1.91026621",
                        "55.2187562,1.97601185",
                        "L55.2187562,2.21342547",
                        "C55.2577165,2.14037476",
                        "55.3125038,2.05758519",
                        "55.3831194,1.96505429",
                        "C55.4537351,1.87252339",
                        "55.5413947,1.78608135",
                        "55.6461007,1.70572557",
                        "C55.7508067,1.62536979",
                        "55.8762085,1.55840764",
                        "56.02231,1.50483712",
                        "C56.1684114,1.4512666",
                        "56.3364255,1.42448174",
                        "56.5263574,1.42448174",
                        "C56.7649897,1.42448174",
                        "56.9658761,1.45857156",
                        "57.1290227,1.52675222",
                        "C57.2921693,1.59493288",
                        "57.4248761,1.69354986",
                        "57.5271471,1.82260612",
                        "C57.6294181,1.95166238",
                        "57.7024677,2.10750155",
                        "57.7462981,2.29012833",
                        "C57.7901286,2.47275511",
                        "57.8120434,2.67607654",
                        "57.8120434,2.90009872",
                        "L57.8120434,4.87245805",
                        "L57.4833169,4.87245805",
                        "L57.4833169,3.00967423",
                        "C57.4833169,2.76130182",
                        "57.461402,2.55432791",
                        "57.4175716,2.3887463",
                        "C57.3737411,2.22316469",
                        "57.309214,2.09167538",
                        "57.2239882,1.99427443",
                        "C57.1387623,1.89687348",
                        "57.0340579,1.82869384",
                        "56.9098717,1.78973346",
                        "C56.7856855,1.75077309",
                        "56.6432387,1.73129319",
                        "56.4825271,1.73129319",
                        "C56.2682451,1.73129319",
                        "56.083186,1.77512296",
                        "55.9273445,1.86278381",
                        "C55.771503,1.95044466",
                        "55.6424487,2.06245407",
                        "55.5401777,2.1988154",
                        "C55.4379067,2.33517673",
                        "55.3612046,2.48614593",
                        "55.3100691,2.65172754",
                        "C55.2589336,2.81730915",
                        "55.2333662,2.98045329",
                        "55.2333662,3.14116486",
                        "L55.2333662,4.87245805",
                        "L54.9046397,4.87245805",
                        "L54.9046397,2.2207305",
                        "Z",
                        "M59.0904245,1.8189536",
                        "L58.4183613,1.8189536",
                        "L58.4183613,1.51214215",
                        "L59.0904245,1.51214215",
                        "L59.0904245,0.4017769",
                        "L59.419151,0.4017769",
                        "L59.419151,1.51214215",
                        "L60.5295163,1.51214215",
                        "L60.5295163,1.8189536",
                        "L59.419151,1.8189536",
                        "L59.419151,3.76209279",
                        "C59.419151,3.96176474",
                        "59.433761,4.1212564",
                        "59.4629812,4.24057256",
                        "C59.4922015,4.35988872",
                        "59.5348138,4.44998324",
                        "59.5908193,4.51085884",
                        "C59.6468249,4.57173443",
                        "59.7113521,4.61191172",
                        "59.7844028,4.63139191",
                        "C59.8574535,4.65087209",
                        "59.9378081,4.66061204",
                        "60.0254689,4.66061204",
                        "C60.1472201,4.66061204",
                        "60.2519245,4.63869716",
                        "60.3395854,4.59486673",
                        "C60.4272462,4.55103631",
                        "60.5149058,4.4755517",
                        "60.6025666,4.36841066",
                        "L60.8071076,4.56564659",
                        "C60.7097067,4.69226783",
                        "60.5989147,4.78966731",
                        "60.4747285,4.85784798",
                        "C60.3505423,4.92602864",
                        "60.1886157,4.96011846",
                        "59.9889437,4.96011846",
                        "C59.8574525,4.96011846",
                        "59.7357031,4.94429104",
                        "59.623692,4.91263574",
                        "C59.5116809,4.88098043",
                        "59.4167164,4.82254074",
                        "59.3387957,4.73731491",
                        "C59.2608749,4.65208908",
                        "59.2000002,4.53764468",
                        "59.1561698,4.39397828",
                        "C59.1123394,4.25031188",
                        "59.0904245,4.06647035",
                        "59.0904245,3.84244817",
                        "L59.0904245,1.8189536",
                        "Z",
                        "M62.7648569,4.96011846",
                        "C62.5262245,4.96011846",
                        "62.3022057,4.9138537",
                        "62.0927937,4.8213228",
                        "C61.8833816,4.7287919",
                        "61.6995401,4.60217257",
                        "61.5412636,4.44146101",
                        "C61.382987,4.28074944",
                        "61.2588027,4.09325543",
                        "61.1687068,3.87897335",
                        "C61.0786109,3.66469126",
                        "61.0335637,3.43580247",
                        "61.0335637,3.1923001",
                        "C61.0335637,2.94879773",
                        "61.0786109,2.71990894",
                        "61.1687068,2.50562685",
                        "C61.2588027,2.29134477",
                        "61.382987,2.10385075",
                        "61.5412636,1.94313919",
                        "C61.6995401,1.78242763",
                        "61.8833816,1.65580829",
                        "62.0927937,1.56327739",
                        "C62.3022057,1.47074649",
                        "62.5262245,1.42448174",
                        "62.7648569,1.42448174",
                        "C63.0034892,1.42448174",
                        "63.227508,1.47074649",
                        "63.43692,1.56327739",
                        "C63.6463321,1.65580829",
                        "63.8301736,1.78242763",
                        "63.9884501,1.94313919",
                        "C64.1467267,2.10385075",
                        "64.270911,2.29134477",
                        "64.3610069,2.50562685",
                        "C64.4511028,2.71990894",
                        "64.49615,2.94879773",
                        "64.49615,3.1923001",
                        "C64.49615,3.43580247",
                        "64.4511028,3.66469126",
                        "64.3610069,3.87897335",
                        "C64.270911,4.09325543",
                        "64.1467267,4.28074944",
                        "63.9884501,4.44146101",
                        "C63.8301736,4.60217257",
                        "63.6463321,4.7287919",
                        "63.43692,4.8213228",
                        "C63.227508,4.9138537",
                        "63.0034892,4.96011846",
                        "62.7648569,4.96011846",
                        "L62.7648569,4.96011846",
                        "Z",
                        "M62.7648569,4.66061204",
                        "C62.9596588,4.66061204",
                        "63.1422828,4.62165225",
                        "63.3127345,4.54373149",
                        "C63.4831861,4.46581073",
                        "63.6305028,4.36110628",
                        "63.754689,4.229615",
                        "C63.8788753,4.09812373",
                        "63.9762747,3.94350204",
                        "64.0468904,3.76574531",
                        "C64.1175061,3.58798858",
                        "64.1528134,3.39684209",
                        "64.1528134,3.1923001",
                        "C64.1528134,2.9974982",
                        "64.1162886,2.81122169",
                        "64.0432379,2.63346496",
                        "C63.9701872,2.45570823",
                        "63.8715702,2.29865155",
                        "63.747384,2.16229023",
                        "C63.6231978,2.0259289",
                        "63.4758811,1.91878946",
                        "63.3054294,1.84086871",
                        "C63.1349778,1.76294795",
                        "62.9547887,1.72398815",
                        "62.7648569,1.72398815",
                        "C62.5651849,1.72398815",
                        "62.3813434,1.76294795",
                        "62.2133268,1.84086871",
                        "C62.0453101,1.91878946",
                        "61.8992109,2.02349391",
                        "61.7750247,2.15498519",
                        "C61.6508385,2.28647647",
                        "61.553439,2.44109816",
                        "61.4828233,2.61885489",
                        "C61.4122076,2.79661162",
                        "61.3769003,2.98775811",
                        "61.3769003,3.1923001",
                        "C61.3769003,3.39197204",
                        "61.4134251,3.57946605",
                        "61.4864758,3.75478776",
                        "C61.5595265,3.93010946",
                        "61.6581435,4.08594864",
                        "61.7823297,4.22230997",
                        "C61.9065159,4.3586713",
                        "62.0538326,4.46581073",
                        "62.2242843,4.54373149",
                        "C62.394736,4.62165225",
                        "62.574925,4.66061204",
                        "62.7648569,4.66061204",
                        "L62.7648569,4.66061204",
                        "Z",
                        "M65.3508391,1.51214215",
                        "L65.6795656,1.51214215",
                        "L65.6795656,4.87245805",
                        "L65.3508391,4.87245805",
                        "L65.3508391,1.51214215",
                        "Z",
                        "M65.2850938,0.328726555",
                        "L65.745311,0.328726555",
                        "L65.745311,0.810858835",
                        "L65.2850938,0.810858835",
                        "L65.2850938,0.328726555",
                        "Z",
                        "M66.7168806,2.2207305",
                        "C66.7168806,2.1281996",
                        "66.7168806,2.04906252",
                        "66.7168806,1.98331688",
                        "C66.7168806,1.91757124",
                        "66.7168806,1.85913155",
                        "66.7168806,1.80799605",
                        "C66.7168806,1.75686055",
                        "66.7156631,1.70816081",
                        "66.713228,1.66189536",
                        "C66.710793,1.61562991",
                        "66.7071405,1.56571267",
                        "66.7022705,1.51214215",
                        "L67.0090819,1.51214215",
                        "C67.013952,1.56571267",
                        "67.0176045,1.61441242",
                        "67.0200395,1.65824284",
                        "C67.0224745,1.70207327",
                        "67.0249095,1.74955552",
                        "67.0273445,1.80069102",
                        "C67.0297795,1.85182651",
                        "67.030997,1.91026621",
                        "67.030997,1.97601185",
                        "C67.030997,2.04175749",
                        "67.030997,2.12089457",
                        "67.030997,2.21342547",
                        "C67.0699574,2.14037476",
                        "67.1247446,2.05758519",
                        "67.1953603,1.96505429",
                        "C67.265976,1.87252339",
                        "67.3536355,1.78608135",
                        "67.4583416,1.70572557",
                        "C67.5630476,1.62536979",
                        "67.6884494,1.55840764",
                        "67.8345508,1.50483712",
                        "C67.9806523,1.4512666",
                        "68.1486664,1.42448174",
                        "68.3385982,1.42448174",
                        "C68.5772305,1.42448174",
                        "68.778117,1.45857156",
                        "68.9412636,1.52675222",
                        "C69.1044102,1.59493288",
                        "69.237117,1.69354986",
                        "69.339388,1.82260612",
                        "C69.441659,1.95166238",
                        "69.5147086,2.10750155",
                        "69.558539,2.29012833",
                        "C69.6023694,2.47275511",
                        "69.6242843,2.67607654",
                        "69.6242843,2.90009872",
                        "L69.6242843,4.87245805",
                        "L69.2955577,4.87245805",
                        "L69.2955577,3.00967423",
                        "C69.2955577,2.76130182",
                        "69.2736429,2.55432791",
                        "69.2298124,2.3887463",
                        "C69.185982,2.22316469",
                        "69.1214549,2.09167538",
                        "69.036229,1.99427443",
                        "C68.9510032,1.89687348",
                        "68.8462987,1.82869384",
                        "68.7221125,1.78973346",
                        "C68.5979263,1.75077309",
                        "68.4554796,1.73129319",
                        "68.294768,1.73129319",
                        "C68.0804859,1.73129319",
                        "67.8954269,1.77512296",
                        "67.7395854,1.86278381",
                        "C67.5837439,1.95044466",
                        "67.4546896,2.06245407",
                        "67.3524186,2.1988154",
                        "C67.2501476,2.33517673",
                        "67.1734455,2.48614593",
                        "67.12231,2.65172754",
                        "C67.0711745,2.81730915",
                        "67.0456071,2.98045329",
                        "67.0456071,3.14116486",
                        "L67.0456071,4.87245805",
                        "L66.7168806,4.87245805",
                        "L66.7168806,2.2207305",
                        "Z",
                        "M73.5178677,4.36841066",
                        "C73.3717663,4.55347246",
                        "73.1733148,4.6983542",
                        "72.9225074,4.80306022",
                        "C72.6717,4.90776624",
                        "72.4148088,4.96011846",
                        "72.1518263,4.96011846",
                        "C71.9034538,4.96011846",
                        "71.6733476,4.91750618",
                        "71.4615005,4.83228036",
                        "C71.2496534,4.74705453",
                        "71.0682469,4.62652266",
                        "70.9172754,4.47068115",
                        "C70.766304,4.31483963",
                        "70.6482071,4.12856311",
                        "70.5629812,3.911846",
                        "C70.4777554,3.69512889",
                        "70.4351431,3.45528266",
                        "70.4351431,3.1923001",
                        "C70.4351431,2.94879773",
                        "70.4753204,2.71990894",
                        "70.5556762,2.50562685",
                        "C70.636032,2.29134477",
                        "70.7468239,2.10385075",
                        "70.8880553,1.94313919",
                        "C71.0292867,1.78242763",
                        "71.1997358,1.65580829",
                        "71.3994077,1.56327739",
                        "C71.5990796,1.47074649",
                        "71.8182285,1.42448174",
                        "72.0568608,1.42448174",
                        "C72.285753,1.42448174",
                        "72.4988144,1.46465903",
                        "72.6960513,1.54501481",
                        "C72.8932883,1.62537059",
                        "73.0637374,1.74346747",
                        "73.2074038,1.89930898",
                        "C73.3510701,2.0551505",
                        "73.4630796,2.24873198",
                        "73.5434353,2.48005923",
                        "C73.6237911,2.71138648",
                        "73.6639684,2.97801758",
                        "73.6639684,3.27996051",
                        "L70.8003949,3.27996051",
                        "C70.8052649,3.46989236",
                        "70.8417897,3.64886392",
                        "70.9099704,3.81688055",
                        "C70.978151,3.98489719",
                        "71.0706806,4.13099642",
                        "71.1875617,4.25518263",
                        "C71.3044428,4.37936883",
                        "71.4444546,4.47676832",
                        "71.6076012,4.54738401",
                        "C71.7707478,4.61799969",
                        "71.9521543,4.65330701",
                        "72.1518263,4.65330701",
                        "C72.2540973,4.65330701",
                        "72.3600192,4.64234957",
                        "72.4695953,4.62043435",
                        "C72.5791713,4.59851914",
                        "72.6850933,4.56564681",
                        "72.7873643,4.52181639",
                        "C72.8896353,4.47798596",
                        "72.9833823,4.42685123",
                        "73.0686081,4.36841066",
                        "C73.1538339,4.30997009",
                        "73.225666,4.24422544",
                        "73.2841066,4.17117473",
                        "L73.5178677,4.36841066",
                        "Z",
                        "M73.3060217,2.99506417",
                        "C73.2621913,2.58111014",
                        "73.130702,2.26699679",
                        "72.9115499,2.05271471",
                        "C72.6923977,1.83843262",
                        "72.4075042,1.73129319",
                        "72.0568608,1.73129319",
                        "C71.8766691,1.73129319",
                        "71.7123074,1.76416552",
                        "71.563771,1.82991115",
                        "C71.4152345,1.89565679",
                        "71.2873977,1.98575132",
                        "71.1802567,2.10019743",
                        "C71.0731156,2.21464355",
                        "70.9866736,2.34856784",
                        "70.9209279,2.50197433",
                        "C70.8551823,2.65538083",
                        "70.81744,2.81974246",
                        "70.8076999,2.99506417",
                        "L73.3060217,2.99506417",
                        "Z",
                    ].join(" ")}
                />
            </g>
        </svg>
    );
};
