/* leny/kourikoulom
 *
 * /src/components/svg/webdeveloper.js - Profession SVG Component
 *
 * coded by leny@flatLand!
 * started at 23/02/2019
 */

import React from "react";

import {css} from "@pwops/emotion-css";
import {varValue} from "../../core/utils";

export default ({className, title}) => {
    const styles = {
        container: css({
            fill: varValue("mainColor"),
        }),
    };

    return (
        <svg
            className={className}
            viewBox={"0 0 56 8"}
            version={"1.1"}
            xmlns={"http://www.w3.org/2000/svg"}>
            <title>{title}</title>
            <g
                stroke={"none"}
                strokeWidth={"1"}
                css={styles.container}
                fillRule={"evenodd"}>
                <path
                    d={[
                        "M0,0",
                        "L0.498235019,0",
                        "L1.97448693,5.52671808",
                        "L3.533778,0.184531488",
                        "L4.07814589,0.184531488",
                        "L5.56362438,5.48981178",
                        "L7.03064971,0",
                        "L7.51043158,0",
                        "L5.78506216,6.15412514",
                        "L5.29605372,6.15412514",
                        "L3.79212209,0.839618272",
                        "L2.21437786,6.15412514",
                        "L1.69768969,6.15412514",
                        "L0,0",
                        "Z",
                        "M11.7362027,5.5174915",
                        "C11.5516703,5.75123256",
                        "11.3010175,5.93422445",
                        "10.9842369,6.06647268",
                        "C10.6674562,6.19872091",
                        "10.3429916,6.26484403",
                        "10.0108332,6.26484403",
                        "C9.69712815,6.26484403",
                        "9.40649396,6.21102289",
                        "9.13892197,6.10337898",
                        "C8.87134997,5.99573507",
                        "8.64222566,5.84349812",
                        "8.45154217,5.64666355",
                        "C8.26085868,5.44982897",
                        "8.11169722,5.21455368",
                        "8.00405331,4.9408306",
                        "C7.89640941,4.66710753",
                        "7.84258826,4.36417136",
                        "7.84258826,4.03201302",
                        "C7.84258826,3.724459",
                        "7.89333391,3.43536256",
                        "7.99482674,3.16471503",
                        "C8.09631956,2.89406749",
                        "8.23625454,2.65725445",
                        "8.41463587,2.4542688",
                        "C8.5930172,2.25128314",
                        "8.80830179,2.09135745",
                        "9.06049608,1.97448693",
                        "C9.31269038,1.8576164",
                        "9.58948484,1.79918201",
                        "9.89088778,1.79918201",
                        "C10.1799886,1.79918201",
                        "10.4490943,1.84992766",
                        "10.698213,1.95142049",
                        "C10.9473318,2.05291332",
                        "11.1626164,2.20207478",
                        "11.3440733,2.39890935",
                        "C11.5255301,2.59574392",
                        "11.6670028,2.8402457",
                        "11.7684957,3.13242202",
                        "C11.8699885,3.42459833",
                        "11.9207342,3.76136493",
                        "11.9207342,4.14273192",
                        "L8.30391698,4.14273192",
                        "C8.31006806,4.38262405",
                        "8.35620047,4.60867286",
                        "8.4423156,4.82088514",
                        "C8.52843072,5.03309741",
                        "8.6452995,5.21762705",
                        "8.79292542,5.3744796",
                        "C8.94055135,5.53133215",
                        "9.11739226,5.65435191",
                        "9.32345345,5.74354258",
                        "C9.52951465,5.83273324",
                        "9.75863895,5.87732791",
                        "10.0108332,5.87732791",
                        "C10.1400059,5.87732791",
                        "10.2737899,5.86348818",
                        "10.4121892,5.83580832",
                        "C10.5505885,5.80812846",
                        "10.6843725,5.76660929",
                        "10.8135452,5.71124957",
                        "C10.9427179,5.65588984",
                        "11.0611244,5.59130447",
                        "11.1687683,5.5174915",
                        "C11.2764122,5.44367854",
                        "11.3671393,5.3606402",
                        "11.4409523,5.268374",
                        "L11.7362027,5.5174915",
                        "Z",
                        "M11.468632,3.78289551",
                        "C11.4132723,3.26005368",
                        "11.2471956,2.86331495",
                        "10.970397,2.59266741",
                        "C10.6935984,2.32201988",
                        "10.3337656,2.18669814",
                        "9.89088778,2.18669814",
                        "C9.66329781,2.18669814",
                        "9.45570196,2.22821731",
                        "9.26809401,2.31125689",
                        "C9.08048606,2.39429648",
                        "8.91902262,2.50808976",
                        "8.78369885,2.65264015",
                        "C8.64837508,2.79719054",
                        "8.53919504,2.96634271",
                        "8.45615546,3.16010174",
                        "C8.37311587,3.35386077",
                        "8.32544572,3.56145662",
                        "8.31314355,3.78289551",
                        "L11.468632,3.78289551",
                        "Z",
                        "M12.9817902,0",
                        "L13.3969861,0",
                        "L13.3969861,2.66648001",
                        "C13.4523458,2.5742138",
                        "13.5215444,2.47733574",
                        "13.604584,2.37584291",
                        "C13.6876236,2.27435009",
                        "13.7906526,2.18054752",
                        "13.9136742,2.09443239",
                        "C14.0366958,2.00831727",
                        "14.1827818,1.93758091",
                        "14.3519365,1.88222118",
                        "C14.5210912,1.82686146",
                        "14.7225361,1.79918201",
                        "14.9562771,1.79918201",
                        "C15.2822844,1.79918201",
                        "15.5652299,1.85607865",
                        "15.805122,1.96987364",
                        "C16.0450141,2.08366863",
                        "16.2433835,2.24051882",
                        "16.400236,2.44042893",
                        "C16.5570886,2.64033905",
                        "16.6739574,2.87715209",
                        "16.7508459,3.15087517",
                        "C16.8277344,3.42459824",
                        "16.866178,3.72138342",
                        "16.866178,4.0412396",
                        "C16.866178,4.39800226",
                        "16.8062059,4.71477815",
                        "16.6862598,4.99157676",
                        "C16.5663138,5.26837538",
                        "16.4002371,5.50057518",
                        "16.1880248,5.68818313",
                        "C15.9758126,5.87579108",
                        "15.7266975,6.01880156",
                        "15.4406723,6.11721884",
                        "C15.1546471,6.21563613",
                        "14.8455599,6.26484403",
                        "14.5134016,6.26484403",
                        "C14.2058475,6.26484403",
                        "13.9152134,6.23870233",
                        "13.6414903,6.18641815",
                        "C13.3677672,6.13413397",
                        "13.1478694,6.07108634",
                        "12.9817902,5.99727337",
                        "L12.9817902,0",
                        "Z",
                        "M13.3877595,5.71124957",
                        "C13.4615725,5.73585389",
                        "13.5892055,5.76968432",
                        "13.7706623,5.81274189",
                        "C13.9521192,5.85579945",
                        "14.202772,5.87732791",
                        "14.5226281,5.87732791",
                        "C14.7932757,5.87732791",
                        "15.0439284,5.83888423",
                        "15.274594,5.76199573",
                        "C15.5052595,5.68510722",
                        "15.7067043,5.5697762",
                        "15.8789346,5.41599919",
                        "C16.0511648,5.26222218",
                        "16.1849488,5.0700038",
                        "16.2802906,4.83933828",
                        "C16.3756323,4.60867277",
                        "16.4233025,4.33956704",
                        "16.4233025,4.03201302",
                        "C16.4233025,3.78596981",
                        "16.395623,3.55069451",
                        "16.3402633,3.32618008",
                        "C16.2849036,3.10166565",
                        "16.197252,2.90637177",
                        "16.0773059,2.7402926",
                        "C15.9573599,2.57421343",
                        "15.8020474,2.44196719",
                        "15.6113639,2.3435499",
                        "C15.4206804,2.24513262",
                        "15.1869429,2.19592471",
                        "14.9101443,2.19592471",
                        "C14.6517989,2.19592471",
                        "14.4272878,2.25128361",
                        "14.2366043,2.36200305",
                        "C14.0459208,2.4727225",
                        "13.8875329,2.61111973",
                        "13.7614358,2.7771989",
                        "C13.6353386,2.94327807",
                        "13.541536,3.12626997",
                        "13.4800252,3.32618008",
                        "C13.4185144,3.52609019",
                        "13.3877595,3.71523308",
                        "13.3877595,3.89361441",
                        "L13.3877595,5.71124957",
                        "Z",
                        "M21.2118946,6.15412514",
                        "C21.2057435,6.0741611",
                        "21.202668,5.99419825",
                        "21.202668,5.9142342",
                        "C21.1965169,5.84657232",
                        "21.1934414,5.76814722",
                        "21.1934414,5.67895656",
                        "L21.1934414,5.41599919",
                        "C21.1011752,5.57592728",
                        "20.9781555,5.7174",
                        "20.8243785,5.84042161",
                        "C20.6952058,5.94498998",
                        "20.5322046,6.04186804",
                        "20.33537,6.1310587",
                        "C20.1385355,6.22024937",
                        "19.9017224,6.26484403",
                        "19.6249238,6.26484403",
                        "C19.2989165,6.26484403",
                        "19.0159711,6.20794739",
                        "18.7760789,6.09415241",
                        "C18.5361868,5.98035742",
                        "18.3378174,5.82504497",
                        "18.1809649,5.6282104",
                        "C18.0241124,5.43137583",
                        "17.9072436,5.19917602",
                        "17.8303551,4.93160403",
                        "C17.7534666,4.66403203",
                        "17.7150229,4.37647334",
                        "17.7150229,4.06891932",
                        "C17.7150229,3.72445882",
                        "17.768844,3.41229617",
                        "17.8764879,3.13242202",
                        "C17.9841319,2.85254786",
                        "18.1348311,2.61419707",
                        "18.3285901,2.4173625",
                        "C18.5223491,2.22052793",
                        "18.7530112,2.06829097",
                        "19.0205832,1.96064706",
                        "C19.2881552,1.85300316",
                        "19.5818649,1.79918201",
                        "19.901721,1.79918201",
                        "C20.0801024,1.79918201",
                        "20.2446413,1.82071047",
                        "20.3953428,1.86376803",
                        "C20.5460442,1.9068256",
                        "20.6829037,1.95295801",
                        "20.8059253,2.00216665",
                        "C20.9412491,2.06367745",
                        "21.0704198,2.13133832",
                        "21.1934414,2.20515129",
                        "L21.1934414,0",
                        "L21.5994107,0",
                        "L21.5994107,5.268374",
                        "L21.5994107,5.61437054",
                        "C21.5994107,5.72201444",
                        "21.6024862,5.81581701",
                        "21.6086373,5.89578106",
                        "C21.6086373,5.99419834",
                        "21.6117128,6.08031218",
                        "21.6178639,6.15412514",
                        "L21.2118946,6.15412514",
                        "Z",
                        "M21.1934414,2.65725343",
                        "C21.1011752,2.57113831",
                        "20.993533,2.49425096",
                        "20.8705113,2.42658907",
                        "C20.765943,2.36507827",
                        "20.6306212,2.30971938",
                        "20.4645421,2.26051073",
                        "C20.2984629,2.21130209",
                        "20.1077823,2.18669814",
                        "19.8924945,2.18669814",
                        "C19.6710556,2.18669814",
                        "19.4573087,2.22206632",
                        "19.2512475,2.29280374",
                        "C19.0451863,2.36354117",
                        "18.8606567,2.4757967",
                        "18.6976531,2.62957371",
                        "C18.5346494,2.78335072",
                        "18.4039409,2.98018234",
                        "18.3055237,3.22007447",
                        "C18.2071064,3.45996661",
                        "18.1578985,3.74598755",
                        "18.1578985,4.07814589",
                        "C18.1578985,4.31803803",
                        "18.1855779,4.54562459",
                        "18.2409376,4.7609124",
                        "C18.2962974,4.97620022",
                        "18.3839489,5.16688085",
                        "18.503895,5.33296002",
                        "C18.6238411,5.49903919",
                        "18.7791535,5.63128543",
                        "18.969837,5.72970272",
                        "C19.1605205,5.82812",
                        "19.3942581,5.87732791",
                        "19.6710567,5.87732791",
                        "C19.929402,5.87732791",
                        "20.1539131,5.82965775",
                        "20.3445966,5.734316",
                        "C20.5352801,5.63897426",
                        "20.693668,5.52056774",
                        "20.8197652,5.37909289",
                        "C20.9458623,5.23761804",
                        "21.0396649,5.08845658",
                        "21.1011757,4.93160403",
                        "C21.1626865,4.77475148",
                        "21.1934414,4.6348165",
                        "21.1934414,4.51179489",
                        "L21.1934414,2.65725343",
                        "Z",
                        "M26.5725343,5.5174915",
                        "C26.3880019,5.75123256",
                        "26.1373492,5.93422445",
                        "25.8205685,6.06647268",
                        "C25.5037879,6.19872091",
                        "25.1793233,6.26484403",
                        "24.8471649,6.26484403",
                        "C24.5334598,6.26484403",
                        "24.2428256,6.21102289",
                        "23.9752536,6.10337898",
                        "C23.7076816,5.99573507",
                        "23.4785573,5.84349812",
                        "23.2878738,5.64666355",
                        "C23.0971904,5.44982897",
                        "22.9480289,5.21455368",
                        "22.840385,4.9408306",
                        "C22.7327411,4.66710753",
                        "22.6789199,4.36417136",
                        "22.6789199,4.03201302",
                        "C22.6789199,3.724459",
                        "22.7296656,3.43536256",
                        "22.8311584,3.16471503",
                        "C22.9326512,2.89406749",
                        "23.0725862,2.65725445",
                        "23.2509675,2.4542688",
                        "C23.4293489,2.25128314",
                        "23.6446335,2.09135745",
                        "23.8968278,1.97448693",
                        "C24.149022,1.8576164",
                        "24.4258165,1.79918201",
                        "24.7272195,1.79918201",
                        "C25.0163202,1.79918201",
                        "25.285426,1.84992766",
                        "25.5345447,1.95142049",
                        "C25.7836635,2.05291332",
                        "25.9989481,2.20207478",
                        "26.1804049,2.39890935",
                        "C26.3618618,2.59574392",
                        "26.5033345,2.8402457",
                        "26.6048273,3.13242202",
                        "C26.7063202,3.42459833",
                        "26.7570658,3.76136493",
                        "26.7570658,4.14273192",
                        "L23.1402487,4.14273192",
                        "C23.1463997,4.38262405",
                        "23.1925321,4.60867286",
                        "23.2786473,4.82088514",
                        "C23.3647624,5.03309741",
                        "23.4816312,5.21762705",
                        "23.6292571,5.3744796",
                        "C23.776883,5.53133215",
                        "23.9537239,5.65435191",
                        "24.1597851,5.74354258",
                        "C24.3658463,5.83273324",
                        "24.5949706,5.87732791",
                        "24.8471649,5.87732791",
                        "C24.9763376,5.87732791",
                        "25.1101216,5.86348818",
                        "25.2485209,5.83580832",
                        "C25.3869202,5.80812846",
                        "25.5207042,5.76660929",
                        "25.6498769,5.71124957",
                        "C25.7790496,5.65588984",
                        "25.8974561,5.59130447",
                        "26.0051,5.5174915",
                        "C26.1127439,5.44367854",
                        "26.203471,5.3606402",
                        "26.277284,5.268374",
                        "L26.5725343,5.5174915",
                        "Z",
                        "M26.3049637,3.78289551",
                        "C26.249604,3.26005368",
                        "26.0835273,2.86331495",
                        "25.8067287,2.59266741",
                        "C25.52993,2.32201988",
                        "25.1700972,2.18669814",
                        "24.7272195,2.18669814",
                        "C24.4996295,2.18669814",
                        "24.2920336,2.22821731",
                        "24.1044257,2.31125689",
                        "C23.9168177,2.39429648",
                        "23.7553543,2.50808976",
                        "23.6200305,2.65264015",
                        "C23.4847068,2.79719054",
                        "23.3755267,2.96634271",
                        "23.2924871,3.16010174",
                        "C23.2094475,3.35386077",
                        "23.1617774,3.56145662",
                        "23.1494752,3.78289551",
                        "L26.3049637,3.78289551",
                        "Z",
                        "M27.0892225,1.90990091",
                        "L27.5597778,1.90990091",
                        "L29.1559752,5.64666355",
                        "L30.742946,1.90990091",
                        "L31.176595,1.90990091",
                        "L29.3312801,6.15412514",
                        "L28.943764,6.15412514",
                        "L27.0892225,1.90990091",
                        "Z",
                        "M35.3101003,5.5174915",
                        "C35.1255679,5.75123256",
                        "34.8749151,5.93422445",
                        "34.5581345,6.06647268",
                        "C34.2413539,6.19872091",
                        "33.9168892,6.26484403",
                        "33.5847309,6.26484403",
                        "C33.2710258,6.26484403",
                        "32.9803916,6.21102289",
                        "32.7128196,6.10337898",
                        "C32.4452476,5.99573507",
                        "32.2161233,5.84349812",
                        "32.0254398,5.64666355",
                        "C31.8347563,5.44982897",
                        "31.6855949,5.21455368",
                        "31.577951,4.9408306",
                        "C31.4703071,4.66710753",
                        "31.4164859,4.36417136",
                        "31.4164859,4.03201302",
                        "C31.4164859,3.724459",
                        "31.4672316,3.43536256",
                        "31.5687244,3.16471503",
                        "C31.6702172,2.89406749",
                        "31.8101522,2.65725445",
                        "31.9885335,2.4542688",
                        "C32.1669149,2.25128314",
                        "32.3821994,2.09135745",
                        "32.6343937,1.97448693",
                        "C32.886588,1.8576164",
                        "33.1633825,1.79918201",
                        "33.4647854,1.79918201",
                        "C33.7538862,1.79918201",
                        "34.0229919,1.84992766",
                        "34.2721107,1.95142049",
                        "C34.5212294,2.05291332",
                        "34.736514,2.20207478",
                        "34.9179709,2.39890935",
                        "C35.0994278,2.59574392",
                        "35.2409005,2.8402457",
                        "35.3423933,3.13242202",
                        "C35.4438862,3.42459833",
                        "35.4946318,3.76136493",
                        "35.4946318,4.14273192",
                        "L31.8778146,4.14273192",
                        "C31.8839657,4.38262405",
                        "31.9300981,4.60867286",
                        "32.0162132,4.82088514",
                        "C32.1023284,5.03309741",
                        "32.2191971,5.21762705",
                        "32.3668231,5.3744796",
                        "C32.514449,5.53133215",
                        "32.6912899,5.65435191",
                        "32.8973511,5.74354258",
                        "C33.1034123,5.83273324",
                        "33.3325366,5.87732791",
                        "33.5847309,5.87732791",
                        "C33.7139036,5.87732791",
                        "33.8476876,5.86348818",
                        "33.9860869,5.83580832",
                        "C34.1244862,5.80812846",
                        "34.2582702,5.76660929",
                        "34.3874429,5.71124957",
                        "C34.5166156,5.65588984",
                        "34.6350221,5.59130447",
                        "34.742666,5.5174915",
                        "C34.8503099,5.44367854",
                        "34.941037,5.3606402",
                        "35.0148499,5.268374",
                        "L35.3101003,5.5174915",
                        "Z",
                        "M35.0425297,3.78289551",
                        "C34.9871699,3.26005368",
                        "34.8210933,2.86331495",
                        "34.5442946,2.59266741",
                        "C34.267496,2.32201988",
                        "33.9076632,2.18669814",
                        "33.4647854,2.18669814",
                        "C33.2371955,2.18669814",
                        "33.0295996,2.22821731",
                        "32.8419917,2.31125689",
                        "C32.6543837,2.39429648",
                        "32.4929203,2.50808976",
                        "32.3575965,2.65264015",
                        "C32.2222727,2.79719054",
                        "32.1130927,2.96634271",
                        "32.0300531,3.16010174",
                        "C31.9470135,3.35386077",
                        "31.8993434,3.56145662",
                        "31.8870412,3.78289551",
                        "L35.0425297,3.78289551",
                        "Z",
                        "M36.5556879,0",
                        "L36.9708837,0",
                        "L36.9708837,6.15412514",
                        "L36.5556879,6.15412514",
                        "L36.5556879,0",
                        "Z",
                        "M40.2186379,6.26484403",
                        "C39.917235,6.26484403",
                        "39.6342895,6.20640965",
                        "39.3697931,6.08953912",
                        "C39.1052966,5.97266859",
                        "38.8730968,5.8127429",
                        "38.6731867,5.60975725",
                        "C38.4732766,5.4067716",
                        "38.3164264,5.16995855",
                        "38.2026314,4.89931102",
                        "C38.0888364,4.62866348",
                        "38.0319398,4.33956704",
                        "38.0319398,4.03201302",
                        "C38.0319398,3.724459",
                        "38.0888364,3.43536256",
                        "38.2026314,3.16471503",
                        "C38.3164264,2.89406749",
                        "38.4732766,2.65725445",
                        "38.6731867,2.4542688",
                        "C38.8730968,2.25128314",
                        "39.1052966,2.09135745",
                        "39.3697931,1.97448693",
                        "C39.6342895,1.8576164",
                        "39.917235,1.79918201",
                        "40.2186379,1.79918201",
                        "C40.5200408,1.79918201",
                        "40.8029863,1.8576164",
                        "41.0674828,1.97448693",
                        "C41.3319792,2.09135745",
                        "41.564179,2.25128314",
                        "41.7640891,2.4542688",
                        "C41.9639992,2.65725445",
                        "42.1208494,2.89406749",
                        "42.2346444,3.16471503",
                        "C42.3484394,3.43536256",
                        "42.405336,3.724459",
                        "42.405336,4.03201302",
                        "C42.405336,4.33956704",
                        "42.3484394,4.62866348",
                        "42.2346444,4.89931102",
                        "C42.1208494,5.16995855",
                        "41.9639992,5.4067716",
                        "41.7640891,5.60975725",
                        "C41.564179,5.8127429",
                        "41.3319792,5.97266859",
                        "41.0674828,6.08953912",
                        "C40.8029863,6.20640965",
                        "40.5200408,6.26484403",
                        "40.2186379,6.26484403",
                        "L40.2186379,6.26484403",
                        "Z",
                        "M40.2186379,5.88655448",
                        "C40.4646811,5.88655448",
                        "40.6953432,5.83734658",
                        "40.910631,5.73892929",
                        "C41.1259188,5.640512",
                        "41.3119862,5.50826576",
                        "41.4688387,5.34218659",
                        "C41.6256913,5.17610742",
                        "41.7487111,4.98081355",
                        "41.8379017,4.75629911",
                        "C41.9270924,4.53178468",
                        "41.971687,4.2903584",
                        "41.971687,4.03201302",
                        "C41.971687,3.78596981",
                        "41.9255546,3.55069451",
                        "41.8332884,3.32618008",
                        "C41.7410222,3.10166565",
                        "41.6164647,2.90329628",
                        "41.4596122,2.73106603",
                        "C41.3027596,2.55883578",
                        "41.1166922,2.42351404",
                        "40.9014044,2.32509675",
                        "C40.6861166,2.22667947",
                        "40.45853,2.17747156",
                        "40.2186379,2.17747156",
                        "C39.9664436,2.17747156",
                        "39.7342438,2.22667947",
                        "39.5220315,2.32509675",
                        "C39.3098193,2.42351404",
                        "39.1252896,2.55576028",
                        "38.9684371,2.72183945",
                        "C38.8115845,2.88791862",
                        "38.6885648,3.0832125",
                        "38.5993741,3.30772693",
                        "C38.5101834,3.53224136",
                        "38.4655888,3.77366765",
                        "38.4655888,4.03201302",
                        "C38.4655888,4.28420732",
                        "38.5117212,4.52102036",
                        "38.6039874,4.74245925",
                        "C38.6962536,4.96389815",
                        "38.8208111,5.16072977",
                        "38.9776636,5.33296002",
                        "C39.1345162,5.50519027",
                        "39.3205836,5.640512",
                        "39.5358714,5.73892929",
                        "C39.7511592,5.83734658",
                        "39.9787458,5.88655448",
                        "40.2186379,5.88655448",
                        "L40.2186379,5.88655448",
                        "Z",
                        "M43.4848453,2.80487862",
                        "L43.4848453,2.50501496",
                        "L43.4848453,2.28357717",
                        "C43.4848453,2.21899083",
                        "43.4833075,2.15748094",
                        "43.480232,2.09904568",
                        "C43.4771564,2.04061042",
                        "43.4725432,1.97756279",
                        "43.4663921,1.90990091",
                        "L43.8539082,1.90990091",
                        "C43.8600593,1.97756279",
                        "43.8646726,2.03753492",
                        "43.8677481,2.08981911",
                        "C43.8708236,2.14210329",
                        "43.8738991,2.19592444",
                        "43.8769747,2.25128416",
                        "C43.8800502,2.30664388",
                        "43.8831257,2.36969151",
                        "43.8862012,2.44042893",
                        "C43.8892768,2.51116636",
                        "43.8908145,2.59574245",
                        "43.8908145,2.69415973",
                        "C43.9215699,2.63880001",
                        "43.9707778,2.56191266",
                        "44.0384397,2.46349537",
                        "C44.1061016,2.36507808",
                        "44.2029797,2.26666228",
                        "44.3290768,2.16824499",
                        "C44.455174,2.0698277",
                        "44.6104864,1.98371387",
                        "44.7950188,1.90990091",
                        "C44.9795512,1.83608794",
                        "45.2009868,1.79918201",
                        "45.4593322,1.79918201",
                        "C45.7853394,1.79918201",
                        "46.0682849,1.8576164",
                        "46.308177,1.97448693",
                        "C46.5480692,2.09135745",
                        "46.7464385,2.25128314",
                        "46.9032911,2.4542688",
                        "C47.0601436,2.65725445",
                        "47.1770124,2.89560524",
                        "47.2539009,3.16932831",
                        "C47.3307894,3.44305139",
                        "47.3692331,3.73983657",
                        "47.3692331,4.05969275",
                        "C47.3692331,4.39185109",
                        "47.3154119,4.6932495",
                        "47.207768,4.96389704",
                        "C47.1001241,5.23454458",
                        "46.9494249,5.46674438",
                        "46.7556659,5.66050341",
                        "C46.5619069,5.85426244",
                        "46.3343203,6.0034239",
                        "46.0728994,6.10799227",
                        "C45.8114785,6.21256063",
                        "45.5269953,6.26484403",
                        "45.2194412,6.26484403",
                        "C44.9180383,6.26484403",
                        "44.6596968,6.22332486",
                        "44.444409,6.14028528",
                        "C44.2291212,6.05724569",
                        "44.0445915,5.97266961",
                        "43.8908145,5.88655448",
                        "L43.8908145,7.99944002",
                        "L43.4848453,7.99944002",
                        "L43.4848453,2.80487862",
                        "Z",
                        "M43.8908145,5.41599919",
                        "C43.9338721,5.44675459",
                        "43.9923065,5.48827376",
                        "44.0661194,5.54055794",
                        "C44.1399324,5.59284212",
                        "44.2321972,5.64358778",
                        "44.3429167,5.69279642",
                        "C44.4536361,5.74200506",
                        "44.5812691,5.78506198",
                        "44.7258195,5.82196846",
                        "C44.8703699,5.85887494",
                        "45.0349088,5.87732791",
                        "45.2194412,5.87732791",
                        "C45.4531823,5.87732791",
                        "45.6746179,5.83734648",
                        "45.8837546,5.75738244",
                        "C46.0928913,5.67741839",
                        "46.2743455,5.56054962",
                        "46.4281225,5.40677261",
                        "C46.5818995,5.2529956",
                        "46.7033815,5.06385272",
                        "46.7925722,4.83933828",
                        "C46.8817628,4.61482385",
                        "46.9263575,4.3549446",
                        "46.9263575,4.05969275",
                        "C46.9263575,3.81364953",
                        "46.8986781,3.57683649",
                        "46.8433183,3.34924652",
                        "C46.7879586,3.12165654",
                        "46.700307,2.92174943",
                        "46.580361,2.74951918",
                        "C46.4604149,2.57728893",
                        "46.3051025,2.44042944",
                        "46.114419,2.33893662",
                        "C45.9237355,2.23744379",
                        "45.6899979,2.18669814",
                        "45.4131993,2.18669814",
                        "C45.2286669,2.18669814",
                        "45.0610525,2.21437758",
                        "44.910351,2.26973731",
                        "C44.7596495,2.32509703",
                        "44.6258655,2.40044664",
                        "44.508995,2.49578838",
                        "C44.3921245,2.59113013",
                        "44.2906332,2.70184791",
                        "44.2045181,2.82794506",
                        "C44.1184029,2.95404221",
                        "44.0507421,3.08475071",
                        "44.0015334,3.22007447",
                        "C43.9584759,3.33079392",
                        "43.9292587,3.46304016",
                        "43.913881,3.61681717",
                        "C43.8985033,3.77059418",
                        "43.8908145,3.92744438",
                        "43.8908145,4.08737247",
                        "L43.8908145,5.41599919",
                        "Z",
                        "M52.1116923,5.5174915",
                        "C51.9271599,5.75123256",
                        "51.6765072,5.93422445",
                        "51.3597265,6.06647268",
                        "C51.0429459,6.19872091",
                        "50.7184813,6.26484403",
                        "50.3863229,6.26484403",
                        "C50.0726178,6.26484403",
                        "49.7819836,6.21102289",
                        "49.5144116,6.10337898",
                        "C49.2468396,5.99573507",
                        "49.0177153,5.84349812",
                        "48.8270318,5.64666355",
                        "C48.6363484,5.44982897",
                        "48.4871869,5.21455368",
                        "48.379543,4.9408306",
                        "C48.2718991,4.66710753",
                        "48.2180779,4.36417136",
                        "48.2180779,4.03201302",
                        "C48.2180779,3.724459",
                        "48.2688236,3.43536256",
                        "48.3703164,3.16471503",
                        "C48.4718092,2.89406749",
                        "48.6117442,2.65725445",
                        "48.7901255,2.4542688",
                        "C48.9685069,2.25128314",
                        "49.1837915,2.09135745",
                        "49.4359858,1.97448693",
                        "C49.6881801,1.8576164",
                        "49.9649745,1.79918201",
                        "50.2663775,1.79918201",
                        "C50.5554782,1.79918201",
                        "50.824584,1.84992766",
                        "51.0737027,1.95142049",
                        "C51.3228215,2.05291332",
                        "51.5381061,2.20207478",
                        "51.7195629,2.39890935",
                        "C51.9010198,2.59574392",
                        "52.0424925,2.8402457",
                        "52.1439853,3.13242202",
                        "C52.2454782,3.42459833",
                        "52.2962238,3.76136493",
                        "52.2962238,4.14273192",
                        "L48.6794067,4.14273192",
                        "C48.6855577,4.38262405",
                        "48.7316901,4.60867286",
                        "48.8178053,4.82088514",
                        "C48.9039204,5.03309741",
                        "49.0207892,5.21762705",
                        "49.1684151,5.3744796",
                        "C49.316041,5.53133215",
                        "49.4928819,5.65435191",
                        "49.6989431,5.74354258",
                        "C49.9050043,5.83273324",
                        "50.1341286,5.87732791",
                        "50.3863229,5.87732791",
                        "C50.5154956,5.87732791",
                        "50.6492796,5.86348818",
                        "50.7876789,5.83580832",
                        "C50.9260782,5.80812846",
                        "51.0598622,5.76660929",
                        "51.1890349,5.71124957",
                        "C51.3182076,5.65588984",
                        "51.4366141,5.59130447",
                        "51.544258,5.5174915",
                        "C51.6519019,5.44367854",
                        "51.742629,5.3606402",
                        "51.816442,5.268374",
                        "L52.1116923,5.5174915",
                        "Z",
                        "M51.8441217,3.78289551",
                        "C51.788762,3.26005368",
                        "51.6226853,2.86331495",
                        "51.3458867,2.59266741",
                        "C51.069088,2.32201988",
                        "50.7092552,2.18669814",
                        "50.2663775,2.18669814",
                        "C50.0387875,2.18669814",
                        "49.8311916,2.22821731",
                        "49.6435837,2.31125689",
                        "C49.4559757,2.39429648",
                        "49.2945123,2.50808976",
                        "49.1591885,2.65264015",
                        "C49.0238648,2.79719054",
                        "48.9146847,2.96634271",
                        "48.8316451,3.16010174",
                        "C48.7486055,3.35386077",
                        "48.7009354,3.56145662",
                        "48.6886332,3.78289551",
                        "L51.8441217,3.78289551",
                        "Z",
                        "M53.375733,2.80487862",
                        "L53.375733,2.50501496",
                        "L53.375733,2.28357717",
                        "C53.375733,2.21899083",
                        "53.3741953,2.15748094",
                        "53.3711197,2.09904568",
                        "C53.3680442,2.04061042",
                        "53.363431,1.97756279",
                        "53.3572799,1.90990091",
                        "L53.744796,1.90990091",
                        "C53.7509471,1.97756279",
                        "53.7555603,2.04214816",
                        "53.7586359,2.10365897",
                        "C53.7617114,2.16516977",
                        "53.7647869,2.23129289",
                        "53.7678624,2.30203032",
                        "C53.770938,2.37276774",
                        "53.7724757,2.45119284",
                        "53.7724757,2.53730797",
                        "L53.7724757,2.84178492",
                        "C53.8216844,2.74951872",
                        "53.8878075,2.64648967",
                        "53.9708471,2.53269468",
                        "C54.0538867,2.41889969",
                        "54.1599912,2.31279515",
                        "54.2891639,2.21437786",
                        "C54.4183366,2.11596058",
                        "54.5690358,2.03292224",
                        "54.741266,1.96526035",
                        "C54.9134963,1.89759847",
                        "55.1134034,1.86376803",
                        "55.3409934,1.86376803",
                        "C55.4763172,1.86376803",
                        "55.5931859,1.88529649",
                        "55.6916032,1.92835405",
                        "L55.5716577,2.32509675",
                        "C55.5286002,2.30664351",
                        "55.4778545,2.29434154",
                        "55.4194193,2.28819046",
                        "C55.360984,2.28203938",
                        "55.3071629,2.27896388",
                        "55.2579542,2.27896388",
                        "C55.0365153,2.27896388",
                        "54.8366082,2.32817179",
                        "54.6582269,2.42658907",
                        "C54.4798455,2.52500636",
                        "54.3260708,2.65417711",
                        "54.1968982,2.8141052",
                        "C54.0677255,2.97403329",
                        "53.9677719,3.15702518",
                        "53.8970345,3.36308638",
                        "C53.8262971,3.56914757",
                        "53.7909289,3.77674342",
                        "53.7909289,3.98588015",
                        "L53.7909289,6.15412514",
                        "L53.375733,6.15412514",
                        "L53.375733,2.80487862",
                        "Z",
                    ].join(" ")}
                />
            </g>
        </svg>
    );
};
